/* ::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #eaeaeb !important;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #f36b24 !important;
  color: red !important;
  border-radius: 8px;
} */

.media {
  width: 140px;
  height: 80px;
  border-radius: 16px;
  object-fit: cover;
}

@media only screen and (max-width: 361px) {
  .media {
    width: 100%;
  }
}
